<template>
  <div class="top">
    <img src="../assets/logo.png" alt="">
  </div>
</template>

<script>
import 'animate.css';
export default {
    data(){
        return{

        }
    },
    methods:{

    }
}
</script>

<style lang="scss" scoped>
    .top{
        width: 100%;
        height: 50px;
        position: fixed;
        top: 0px;
        z-index: 2;
        @keyframes shake {
            0% { transform:   rotate(45deg); }
            25% { transform:  rotate(90deg); }
            50% { transform:  rotate(180deg); }
            75% { transform:  rotate(360deg); }
            100% { transform: rotate(1800deg); }
        }
        img{
            width: 50px;
            height: 50px;
            overflow: hidden;
            border-radius: 50%;
            animation: shake 1s infinite linear;
        }
    }
</style>