<template>
  <div id="app" ref="vantaRef">
    <div class="wais">
      <router-view/>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three'
import HALO from 'vanta/src/vanta.halo'
 
export default {
    data(){
        return{

        }
    },
    mounted() {
      // let dom = document.querySelector("body");
      let dom = this.$refs.vantaRef
      this.vantaEffect = HALO({
        el: dom,
        THREE: THREE
      })
      VANTA.HALO({
        el: dom,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00
      })

    },
    beforeDestroy() {
      if (this.vantaEffect) {
        this.vantaEffect.destroy()
      }
    },
}

</script>

<style lang="scss">
body,html{
  padding: 0;
  margin: 0;
}
#app{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #000;
  box-sizing: border-box;
  .wais{
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
}
.vanta-canvas{
  width: 100% !important;
}
</style>
